import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'typeface-roboto'
import React    from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import App      from './App'
import * as serviceWorker from './serviceWorker'
import { LOGO_BLUE, LOGO_RED, GREEN } from './styles/colors'

const theme = createTheme({
  palette: {
    primary: {
      main: LOGO_BLUE
    },
    secondary: {
      main: LOGO_RED
    },
    error: {
      main: LOGO_RED
    },
    success: {
      main: GREEN
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
