import React  from 'react'
import { Button, Fab }  from '@material-ui/core'
import { WHITE, BLACK, BLUE } from '../../styles/colors'

const MyButton = ({
  type,
  variant,
  buttonType,
  label,
  fullWidth,
  light,
  disabled,
  autoFocus,
  style,
  labelStyle,
  onClick,
  href
}: MyButtonProps) => {
  switch (buttonType) {
    case 'floating':
      return (
        <Fab
          style={style}
          disabled={disabled}
          onClick={onClick}
        >
          <div style={{ ...style, color: style.color || BLUE }}>{label}</div>
        </Fab>
      )

    case 'flat':
      return (
        <Button
          disabled={disabled}
          onClick={onClick}
          href={href}
        >
          <div style={{ ...style, color: style.color || BLUE }}>{label}</div>
        </Button>
      )

    default:
      return (
        <Button
          variant={variant || 'contained'}
          fullWidth={fullWidth}
          style={{ ...style, maxHeight: '35px' }}
          disabled={disabled}
          autoFocus={autoFocus}
          onClick={onClick}
        >
          <div style={{ ...labelStyle, color: light ? WHITE : BLACK }}>{label}</div>
          {type === 'submit' && <input type="submit" style={{ visibility: 'hidden', position: 'absolute' }} />}
        </Button>
      )
  }
}

interface MyButtonProps {
  label: string | any;
  onClick?: (key?: any) => any;
  buttonType?: string; // | floating | flat
  variant?: any;
  style?: any;
  type?: string;
  fullWidth?: boolean;
  light?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  labelStyle?: Record<string, unknown>;
  href?: string;
}

export default MyButton
