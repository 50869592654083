import React                from 'react'
import { AppBar, Toolbar }  from '@material-ui/core'
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  TumblrShareButton,
  FacebookIcon,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon
} from 'react-share'

import { FooterHeading, SocialMedia, SocialMediaNetworks, SocialMediaBtn } from '../../styles/common'

const FooterBar = () => {
  const shareUrl = 'https://www.colorkeysprinting.com/'
  const title = 'Color Keys Printing'

  return (
    <AppBar position="fixed" style={{ backgroundColor: '#000', bottom: 0, top: 'auto' }}>
      <Toolbar>
        <FooterHeading>Tell about us on:</FooterHeading>
        <div className="fb-like"
          data-href="https://www.facebook.com/colorkeysprinting"
          data-layout="standard"
          data-action="like"
          data-show-faces="true">
        </div>
        <SocialMedia>
          <SocialMediaNetworks>
            <SocialMediaBtn>
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </SocialMediaBtn>
          </SocialMediaNetworks>

          <SocialMediaNetworks>
            <SocialMediaBtn>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </SocialMediaBtn>
          </SocialMediaNetworks>

          <SocialMediaNetworks>
            <SocialMediaBtn>
              <TelegramShareButton url={shareUrl} title={title}>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </SocialMediaBtn>
          </SocialMediaNetworks>

          <SocialMediaNetworks>
            <SocialMediaBtn>
              <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </SocialMediaBtn>
          </SocialMediaNetworks>

          <SocialMediaNetworks>
            <SocialMediaBtn>
              <RedditShareButton
                url={shareUrl}
                title={title}
                windowWidth={660}
                windowHeight={460}>
                <RedditIcon size={32} round />
              </RedditShareButton>
            </SocialMediaBtn>
          </SocialMediaNetworks>

          <SocialMediaNetworks>
            <SocialMediaBtn>
              <TumblrShareButton
                url={shareUrl}
                title={title}
                windowWidth={660}
                windowHeight={460}>
                <TumblrIcon size={32} round />
              </TumblrShareButton>
            </SocialMediaBtn>
          </SocialMediaNetworks>
        </SocialMedia>
      </Toolbar>
    </AppBar>
  )
}

export default FooterBar
