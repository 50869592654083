import React from 'react'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import _ from 'lodash'

const GoogleMaps = ({ google, initialCenter, markers }) => (
  <Map
    google={google}
    zoom={17}
    initialCenter={initialCenter}
  >
    {_.map(markers, (marker, key) => (
      <Marker
        key={key}
        title={marker.title}
        name={marker.name}
        position={marker.position}
      />
    ))}
  </Map>
)

export default GoogleApiWrapper({
  apiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`
})(GoogleMaps)