import _ from 'lodash'

export const detectMobile = (): boolean => (
  _.includes(navigator.userAgent, 'Android') ||
  _.includes(navigator.userAgent, 'webOS') ||
  _.includes(navigator.userAgent, 'iPhone') ||
  _.includes(navigator.userAgent, 'iPad') ||
  _.includes(navigator.userAgent, 'iPod') ||
  _.includes(navigator.userAgent, 'BlackBerry') ||
  _.includes(navigator.userAgent, 'Windows Phone')
)

