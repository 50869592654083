import React, { useState, useRef, useEffect, useCallback, createRef }  from 'react'
import { detectMobile }     from './utils/siteFunctions'
import _                    from 'lodash'
import { Grid, Card, Paper, CardContent } from '@material-ui/core'
import { createStyles, makeStyles, Theme }  from '@material-ui/core/styles'

import PhoneIcon  from '@material-ui/icons/Phone'
import MapIcon    from '@material-ui/icons/Map'
import EmailIcon  from '@material-ui/icons/Email'
import { Html, CardIcon, Li, ScrollElement } from './styles/common'
import { LOGO_BLUE, LOGO_RED, LOGO_YELLOW, BLACK } from './styles/colors'
import logoImg    from './assets/images/logo.png'
import productImg from './assets/images/products.jpeg'

import Layout     from './components/Layout'
import ContactUs  from './components/molecules/ContactUs'
import Maps       from './components/molecules/Maps'
import { LinkTypes } from './@types/linkTypes'

const Home = () => {
  const classes = useStyles()

  const titleRef = useRef()
  const navRef = useRef()
  const contactRef = useRef()

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  const products = {
    business: [
      'business cards',
      'appointment cards',
      'brochures',
      'letterhead',
      'envelopes',
      'carbonless forms',
      'flyers',
      'labels',
      'newsletters',
      'notepads',
      'numbered forms',
      'postcards',
      'thank you cards',
    ],
    general: [
      'announcements',
      'booklets',
      'flyers',
      'giclee art prints',
      'greeting cards',
      'labels',
      'large format prints',
      'postcards',
      'posters',
      'programs',
      'thank you cards',
      'tickets',
      'wedding invitations',
    ],
    services: [
      'numbering',
      'laminating',
      'color copies',
      'vinyl lettering',
      'graphic design',
      'spiral binding',
      'padding',
      'canvas wraps',
      'direct mail',
      'fulfillment',
      'free delivery',
    ],
    promotional: ['flyers', 'posters', 'banners', 'tickets', 'calendars', 'magnets'],
  }

  const links = [
    {
      type: LinkTypes.Call,
      label: 'Call',
      icon: <PhoneIcon style={{ color: BLACK }} />,
      color: LOGO_BLUE,
      href: 'tel:208-589-7436'
    },
    {
      type: LinkTypes.Navigation,
      label: 'Navigation',
      icon: <MapIcon style={{ color: BLACK }} />,
      color: LOGO_RED,
      route: '/navigation',
    },
    {
      type: LinkTypes.Contact,
      label: 'Contact',
      icon: <EmailIcon style={{ color: BLACK }} />,
      color: LOGO_YELLOW,
      route: '/contact',
    }
  ]

  const _setWindowDimensions = useCallback(() => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    setIsMobile(false)
    if (window.innerWidth < 720) {
      setIsMobile(true)
    }
  }, [])

  const _scrollTo = (route?: string) => {
    const navRefElement:any = navRef.current ?? createRef().current
    const contactRefElement:any = contactRef.current ?? createRef().current
    const titleRefElement:any = titleRef.current ?? createRef().current

    switch(route) {
      case '/navigation':
        navRefElement.scrollIntoView({ behavior: 'smooth'  })
        break
      case '/contact':
        contactRefElement.scrollIntoView({ behavior: 'smooth' })
        break
      default:
        titleRefElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const _getDirections = () => {
    const addr = '3342+East+113+North,+Idaho+Falls,+ID+83401,+United+States'
    if (detectMobile()) {
      _.includes(navigator.userAgent, 'iPhone') ||
      _.includes(navigator.userAgent, 'iPad') ||
      _.includes(navigator.userAgent, 'iPod')
        ? window.open(`http://maps.apple.com/?daddr=${addr}&amp&z=17ll=`)
        : window.open(`http://maps.google.com/?daddr=${addr}&amp&z=17ll=`)
    } else {
      window.open(`https://maps.google.com/maps?daddr=${addr}&amp&z=17ll=`)
    }
  }

  useEffect(() => {
    if (!height && !width) {
      _setWindowDimensions()
    }
    window.addEventListener('resize', () => _setWindowDimensions())

    return () => {
      window.removeEventListener('resize', () => _setWindowDimensions())
    }
  }, [_setWindowDimensions, height, width])


  return (
    <Html>
      <Layout
        isMobile={isMobile}
        links={links}
        scrollTo={_scrollTo}
        getDirections={_getDirections}
      >
        <div>
          <ScrollElement ref={titleRef} height="0" />
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card
                  raised
                  className={classes.card}
                >
                  <CardContent>
                    <p>
                      We specialize in producing high quality products with a personal touch to
                      ensure the final product has the best presentation. We offer small quantity
                      full color and black and white printing from business cards to posters and
                      anything in between!
                    </p>
                    <p>
                      You don't need to order thousands to get a great price, and everything is
                      printed in-house so you are not waiting weeks for your product.
                    </p>
                    <p>
                      Our creative team will work with you on your project from start to finish to
                      ensure you get a quality final product. With over 20 years of experience and
                      knowledge we are confident we can help with your project. You can also send
                      us your designs to have printed. Give us a call today to see how we can help
                      with your printing needs.
                    </p>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper
                  style={{
                    backgroundColor: '#000'
                  }}
                >
                  <img
                    src={productImg}
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>

          <div className={classes.root} >
            <Paper className={classes.sectionTitle} >
              Products
            </Paper>
            <Grid container direction="row" spacing={2}>
              {_.map(products, (product, key) => (
                <Grid key={`element${key}`} item xs={12} sm={3}>
                  <Card className={classes.card}>
                    <CardContent>
                      <CardIcon src={logoImg} alt="" />
                      <h5>{key}</h5>
                      <ul>
                        {product.map((product, key) => (
                          <Li key={`product${key}`}>
                            {product}
                          </Li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>

          <ContactUs
            ref={contactRef}
            isMobile={isMobile}
          />

          <Maps
            ref={navRef}
            isMobile={isMobile}
            height={height}
            getDirections={_getDirections}
          />

        </div>
      </Layout>
    </Html>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '10px'
    },
    fab: {
      margin: theme.spacing(),
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(2),
      zIndex: 9999,
      backgroundColor: LOGO_BLUE,
      color: '#FFF',
    },
    fabTop: {
      margin: theme.spacing(),
      position: 'absolute',
      top: theme.spacing(2),
      left: theme.spacing(30),
      zIndex: 9999,
      backgroundColor: LOGO_BLUE,
      color: '#FFF',
    },
    extendedIcon: {
      marginRight: theme.spacing(),
    },
    card: {
      textAlign: 'center',
      fontSize: '20px'
    },
    sectionTitle: {
      backgroundColor: LOGO_YELLOW,
      textAlign: 'center',
      margin: '20px 0',
      fontSize: '24px',
      padding: '10px',
      fontWeight: 'bold'
    }
  }),
)


export default Home