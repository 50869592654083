import React, { useState, ReactElement, Fragment } from 'react'
import { Parallax } from 'react-parallax'
import clsx from 'clsx'
import _ from 'lodash'
import styled from 'styled-components'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Button from './atoms/Button'
import Footerbar  from './molecules/FooterBar'
import { BLACK, WHITE } from '../styles/colors'
import fullLogoImg    from '../assets/images/full_logo.png'
import backgroundImg  from '../assets/images/paint_splatter_large_1920_1080.jpg'
import { SideNavRoute } from '../@types/ui'
import { LinkTypes } from '../@types/linkTypes'

const drawerWidth = 240

const Layout = ({ isMobile, links, scrollTo, getDirections, children }: LayoutProps, ref: any) => {
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const _openDrawer = () => setOpen(true)
  const _closeDrawer = () => setOpen(false)
  const _linkClick = (route: SideNavRoute) => {
    if (route.type === LinkTypes.Navigation) {
      isMobile
        ? getDirections()
        : scrollTo(route.route)

    } else if (route.type === LinkTypes.Call) {
      const  a = document.createElement('a')
      a.target = '_self'
      a.href = `${route.href}`
      a.click()

    } else {
      scrollTo(route.route)
    }

    _closeDrawer()
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: BLACK }}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={_openDrawer}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon style={{ color: WHITE }} />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            onClick={() => scrollTo()}
          >
            <Logo src={fullLogoImg} alt="Color Keys Printing" />
          </IconButton>
          {!isMobile && (
            <Fragment>
              <ButtonContainer>
                {_.map(links, (link, key) => (
                  <Button
                    key={key}
                    buttonType="flat"
                    variant="flat"
                    label={<Typography variant="h6" noWrap>{link.label}</Typography>}
                    onClick={() => _linkClick(link)}
                    style={{ color: link.color, marginLeft: '5px', marginRight: '5px' }}
                  />
                ))}
              </ButtonContainer>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={_closeDrawer}>
            {theme.direction === 'ltr'
              ? <ChevronLeftIcon style={{ color: WHITE }} />
              : <ChevronRightIcon style={{ color: WHITE }} />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {_.map(links, (link, key) => (
            <ListItem
              key={key}
              onClick={() => _linkClick(link)}
              style={{ backgroundColor: link.color }}
              button
            >
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText primary={<Typography variant="h6" noWrap style={{ color: BLACK }}>{link.label}</Typography>} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Parallax
          blur={{ min: isMobile ? -20 : -10, max: isMobile ? 20 : -10 }}
          bgImage={backgroundImg}
          strength={isMobile ? -600 : -1200}
          style={{ overflow: 'visible' }}
        >
          {children}
        </Parallax>
      </main>
      {!isMobile && <Footerbar />}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: BLACK,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    marginTop: '20px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}))

const ButtonContainer = styled.div `
  display: inline;
`
const Logo = styled.img `
  height: 75px;
  width: auto;
  margin-right: 10px;
`


interface LayoutProps {
  isMobile: boolean;
  links: SideNavRoute[];
  scrollTo: (route?: string) => void;
  getDirections: () => void;
  children: ReactElement;
}

export default Layout