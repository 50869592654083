import React, { forwardRef } from 'react'
import NavigationIcon from '@material-ui/icons/Navigation'
import { Grid, Paper, Fab, makeStyles, Theme, createStyles } from '@material-ui/core'
import GoogleMaps from '../atoms/GoogleMaps'
import { LOGO_BLUE } from '../../styles/colors'
import { ScrollElement } from '../../styles/common'

const Maps = ({ isMobile, height, getDirections }: GoogleMapsProps, ref: any) => {
  const classes = useStyles()

  const _getDirections = (e: any) => {
    e.preventDefault()
    getDirections()
  }

  return (
    <div className={classes.root} >
      <ScrollElement ref={ref} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: '#000',
              position: 'relative',
              height: isMobile ? '300px' : height - 150
            }}
          >
            <span>
              {!isMobile && (
                <Fab
                  variant="extended"
                  aria-label="directions"
                  className={classes.fab}
                  name="directions"
                  onClick={_getDirections}
                >
                  <NavigationIcon className={classes.extendedIcon} />
                  Get Directions
                </Fab>
              )}
            </span>
            <GoogleMaps
              initialCenter={{
                lat: 43.5969747,
                lng: -111.9670886,
              }}
              markers={[
                {
                  title: 'Color Keys Printing',
                  name: 'Color Keys Printing',
                  position: {
                    lat: 43.5969747,
                    lng: -111.9670886,
                  }
                }
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: '10px'
    },
    fab: {
      margin: theme.spacing(),
      position: 'absolute',
      top: theme.spacing(0),
      left: theme.spacing(25),
      zIndex: 9999,
      backgroundColor: LOGO_BLUE,
      color: '#FFF',
    },
    extendedIcon: {
      marginRight: theme.spacing(),
    },
  }),
)

interface GoogleMapsProps {
  isMobile: boolean;
  height: number;
  getDirections: () => void;
}

export default forwardRef(Maps)
